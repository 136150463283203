
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Notifications</h5>
      <div class="row mb-4 g-3">
        <div class="col-sm-12 col-md-7 d-flex">
          <div class="me-2">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <div class="me-2">
            <select @change="fetchItems()" id="status-select" v-model="filter"  class="form-select">
                <option disabled selected>-filter-</option>
                <option value='0'>Unread</option>
                <option value='1'>Read</option>
                <option value='all'>Show all</option>
            </select>
          </div>
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a @click="selectAllRows()" class="dropdown-item" href="javascript:void(0)">
                <i class="ri" :class="isSelectAll? 'ri-checkbox-blank-circle-line' : ' ri-checkbox-circle-line'" ></i> {{isSelectAll? 'Invert selection' : 'Select all' }}</a></li>
              <li><a @click="deleteItems()" class="dropdown-item" href="javascript:void(0)"><i class="ri ri-delete-bin-line"></i> Delete</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
           <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover table-class="table table-centered w-100" thead-tr-class=""
               ref="selectableTable" :items="pageData.data"
               :fields="columns" responsive="sm">
                  <template v-slot:cell(check)="data">
                      <div class="form-check text-center mx-auto">
                          <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input" :id="`tableSRadio${data.item.id}`" />
                          <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                      </div>
                  </template>
                  <template v-slot:cell(title)="data">
                    <div @click="navigateTo(data.item)" class="d-inline-block cursor-pointer">
                      <p class="mb-1">
                          <span  class="text-dark me-2">{{ data.item.title }}</span>
                          <span v-if="!data.item.is_read" class="badge bg-primary">new</span>
                      </p>
                      <span @click="navigateTo(data.item)" class="text-dark small">{{ data.item.content }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(date)="data">
                      <span class="cursor-pointer" @click="navigateTo(data.item)">{{$filters.date_ago(data.item.created_at)}} </span>
                  </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
  components: { IsLoading },
  name: "notification-list",
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      filter: "all",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: ""
          },
          {
            key: "title",
            label: "Title",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
      ]
    }
  },
  computed: {
    pageData() {
      return this.$store.state.notification.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 50
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    navigateTo(notif){
      if(!notif.is_read){
        this.$store.dispatch("notification/markAsRead", notif.id);
      }
      this.$router.push({path: notif.url});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.filter != 'all'){ payload.is_read = this.filter }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("notification/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    deleteItems() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} notification${this.selected.length > 1 ? 's':'y' }`;
      
      this.confirmDelete({
        text: text,
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/notifications/delete',formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchItems()
            }
          })
        }
      });
    },
  },
}

</script>

